import React from 'react';
import loadable from '@loadable/component';
import WorkersPageLoading from './WorkersPageLoading';

const WorkersPage = loadable(
  () => import(
    /* webpackChunkName: "workers-page-chunk" */
    './WorkersPage'
  ),
  {
    fallback: (
      <WorkersPageLoading />
    ),
  }
);

export default WorkersPage;
