import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/ShiftPage/ShiftCreateLoading';

const ShiftCreatePage = loadable(
  () => import(
    /* webpackChunkName: "shift-create-chunk" */
    './ShiftCreatePage'
  ),
  {
    fallback: (
      <LoadingComponent />
    ),
  }
);

export default ShiftCreatePage;
