import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const WorkerCalendar = loadable(
  () => import(
    /* webpackChunkName: "worker-calendar-chunk" */ './WorkerCalendar'
  ),
  {
    fallback: (
      <Preloader isDefault={config.isLogisticForce} />
    ),
  }
);

export default WorkerCalendar;
