import React, {
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import cookieHelper from '../utils/cookieHelper';

export const useDebounce = (value, timeout) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

export const useIsMountedRef = () => {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
};

export const withNavigation = (Component) => (props) => (
  <Component
    {...props}
    paths={useParams()}
    location={useLocation()}
    onNavigate={useNavigate()}
  />
);

export const useDraggableLine = (ref, options) => {
  const {
    onDefViewBlocks = () => {},
  } = options;

  const [initialSize, setInitialSize] = useState('');
  const [isDraggable, setIsDraggable] = useState(false);

  useEffect(() => {
    const planningHeight = cookieHelper.get('planningHeight');
    if (planningHeight) {
      setInitialSize(+planningHeight);
    }
  }, []);

  const onSetDefSize = () => {
    setInitialSize('');
    cookieHelper.remove('planningHeight');
  };

  const onMouseMove = (mouseMoveEvent) => {
    const element = ref.current;

    if (!element) {
      return;
    }
    const parentHeight = element.clientHeight;
    const calcHeight = ((mouseMoveEvent.pageY - 90) * 100) / parentHeight;
    const heightToInt = parseInt(calcHeight, 10);

    if (heightToInt > 0 && heightToInt !== initialSize) {
      onDefViewBlocks();

      setInitialSize(heightToInt);
      cookieHelper.save('planningHeight', heightToInt);
    }
  };

  const onMouseUp = () => {
    setIsDraggable(false);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = () => {
    setIsDraggable(true);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };
  return {
    onMouseDown,
    onSetDefSize,
    initialSize,
    isDraggable,
  };
};

export const useDraggableBorder = (ref) => {
  const [initialSize, setInitialSize] = useState('');
  const [isDraggable, setIsDraggable] = useState(false);

  useLayoutEffect(() => {
    const filterBarWidth = cookieHelper.get('filterBarWidth');
    if (filterBarWidth) {
      setInitialSize(+filterBarWidth);
    }
  }, []);

  const onSetDefSize = () => {
    setInitialSize('');
    cookieHelper.remove('filterBarWidth');
  };

  const onMouseMove = (mouseMoveEvent) => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const calculateWidth = mouseMoveEvent.pageX - 70;
    const widthToInt = parseInt(calculateWidth, 10);

    if (widthToInt < 235) {
      return;
    }

    setInitialSize(widthToInt);
    cookieHelper.save('filterBarWidth', widthToInt);
  };

  const onMouseUp = () => {
    setIsDraggable(false);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = () => {
    setIsDraggable(true);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };
  return {
    onMouseDown,
    onSetDefSize,
    initialSize,
    isDraggable,
  };
};

// usePortal is an implementation of the facade pattern.
export const usePortal = () => {
  // Creates only one instance of div.
  const wrapper = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    // Adds div tag to body.
    document.body.appendChild(wrapper);

    return () => {
      // After unmounting the component - removes the div created earlier.
      document.body.removeChild(wrapper);
    };
  }, []);

  // Returns an object with function that will allow you to use the portal.
  return {
    // This anonymous function is an implementation of the factory method pattern.
    render: (children) => createPortal(children, wrapper),
  };
};
