import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const WorkersEnrichPage = loadable(
  () => import(
    /* webpackChunkName: "workers-enrich-page-chunk" */
    './WorkersEnrichPage'
  ),
  {
    fallback: (
      <Preloader isDefault={config.isLogisticForce} />
    ),
  }
);

export default WorkersEnrichPage;
