import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const WorkersInvitePage = loadable(() => import(
  /* webpackChunkName: "workers-invite-page-chunk" */
  './WorkersInvitePage'
), {
  fallback: (
    <Preloader isDefault={config.isLogisticForce} />
  ),
});

export default WorkersInvitePage;
