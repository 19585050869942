import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../components/LoadingComponent';

const SignupPage = loadable(
  () => import(
    /* webpackChunkName: "signup-page-chunk" */
    './SignupPage'
  ),
  {
    fallback: (
      <LoadingComponent />
    ),
  }
);

export default SignupPage;
