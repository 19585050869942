import React from 'react';
import loadable from '@loadable/component';
import ShiftCreateLoading from '../../components/ShiftPage/ShiftCreateLoading';

const ShiftDetailPage = loadable(
  () => import(
    /* webpackChunkName: "shift-detail-chunk" */
    './ShiftDetailPage'
  ),
  {
    fallback: (
      <ShiftCreateLoading />
    ),
  }
);

export default ShiftDetailPage;
