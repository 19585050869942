import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getFeatureIsTimeTracking,
  getFeatureIsTimeTrackingSign,
  getFeatureIsImportingShifts,
  getFeatureIsBranding,
  getFeatureIsRecurring,
  getFeatureIsExtendedReport,
  getFeatureIsOrtSchema,
  getFeatureIsSimpleOrtSchema,
  getFeatureIsInternalManagers,
  getFeatureIsExpenses,
  getFeatureIsExternalManagers,
  getFeatureIsMessaging,
  getFeatureIsCCemail,
  getFeatureIsManualApprove,
  getFeatureIsAdvancedFiltering,
  getFeatureIsCustomFields,
  getFeatureIsMultiRoles,
  getFeatureIsFull,
  getFeatureIsFullDays,
  getIsFreemium,
  getFeatureIsCalculated,
  getFeatureIsCopyShift,
  getFeatureIsWorkerCalendarView,
  getFeatureIsFavoriteWorkersList,
  getFeatureIsBlacklistWorkersList,
  getFeatureIsAdditionalWorkerStatuses,
  getFeatureIsStoreFilter,
  getFeatureIsAutoApprove,
  getFeatureIsGigCategorySettings,
  getFeatureIsReview,
  getFeatureIsPublicProfile,
  getFeatureIsNewDashboard,
  getFeatureIsOrgGroup,
  getFeatureIsBilling,
  getFeatureIsMultiBillingInfo,
  getFeatureIsMultiDays,
  getPreparedDefaultScheduleType,
  getFeatureIsAgencyFee,
  getAllowedAgencyFees,
  getAllowedUnavailabilities,
  getFeatureIsCreditInvoice,
  getFeatureIsSummaryInvoice,
  getFeatureIsSellingExpenses,
  getFeatureIsFeeInvoice,
  getFeatureIsOrgAttributesFilter,
  getFeatureIsPlacementEntities,
  getFeatureIsPlannerDistance,
  getFeatureIsManagerConfigurable,
  getFeatureIsProjects,
  getFeatureIsWorkerOnboarding,
  getFeatureIsExactSolid,
  getFeatureIsSDB,
  getFeatureIsWorkingTimeRegulations,
  getIsFeatureProtectedWorkersDocuments,
  getIsFeatureAgreements,
  getDefaultOpenShift,
  getIsSigningDocuments,
  getCanCreateOrg,
  getIsMaintenance,
  getIsExternal,
  getIsExternalBlocked,
  getIsInternalBlocked,
  getIsInternalBlockedSwzzorg,
  getIsInternalBlockedFletcher,
  getIsBlockedKeistadZorg,
  getIsYzorgManagers,
  getExternalExcellentFlex,
  getIsInternalBlockedKiz,
  getIsLogisticForceManager,
  getManagerAccessBilling,
  getManagerAccessTimeTracking,
  getManagerAccessSettings,
  getManagerAccessDeletingActions,
  getManagerCanPublishShift,
  getManagerCanUpdatePendingShift,
  getManagerCanDeletePendingShift,
  getManagerCanUpdateActiveShift,
  getManagerCanDeleteActiveShift,
  getManagerCanApproveShift,
} from '../services/selectors';

const mapStateToProps = (state) => ({
  featureIsTimeTracking: getFeatureIsTimeTracking(state),
  featureIsAgencyFee: getFeatureIsAgencyFee(state),
  featureIsTimeTrackingSign: getFeatureIsTimeTrackingSign(state),
  featureIsImportingShifts: getFeatureIsImportingShifts(state),
  featureIsBranding: getFeatureIsBranding(state),
  featureIsRecurring: getFeatureIsRecurring(state),
  featureIsExtendedReport: getFeatureIsExtendedReport(state),
  featureIsOrtSchema: getFeatureIsOrtSchema(state),
  featureIsSimpleOrtSchema: getFeatureIsSimpleOrtSchema(state),
  featureIsInternalManagers: getFeatureIsInternalManagers(state),
  featureIsExpenses: getFeatureIsExpenses(state),
  featureIsExternalManagers: getFeatureIsExternalManagers(state),
  featureIsMessaging: getFeatureIsMessaging(state),
  featureIsCCemail: getFeatureIsCCemail(state),
  featureIsManualApprove: getFeatureIsManualApprove(state),
  featureIsAdvancedFiltering: getFeatureIsAdvancedFiltering(state),
  featureIsCustomFields: getFeatureIsCustomFields(state),
  featureIsMultiRoles: getFeatureIsMultiRoles(state),
  featureIsFull: getFeatureIsFull(state),
  featureIsFullDays: getFeatureIsFullDays(state),
  featureIsCopyShift: getFeatureIsCopyShift(state),
  featureIsWorkerCalendarView: getFeatureIsWorkerCalendarView(state),
  featureIsFavoriteWorkersList: getFeatureIsFavoriteWorkersList(state),
  featureIsBlacklistWorkersList: getFeatureIsBlacklistWorkersList(state),
  featureIsAdditionalWorkerStatuses: getFeatureIsAdditionalWorkerStatuses(state),
  featureIsStoreFilter: getFeatureIsStoreFilter(state),
  featureIsAutoApprove: getFeatureIsAutoApprove(state),
  featureIsCalculated: getFeatureIsCalculated(state),
  featureIsGigCategorySettings: getFeatureIsGigCategorySettings(state),
  featureIsReview: getFeatureIsReview(state),
  featureIsPublicProfile: getFeatureIsPublicProfile(state),
  featureIsNewDashboard: getFeatureIsNewDashboard(state),
  featureIsOrgGroup: getFeatureIsOrgGroup(state),
  featureIsBilling: getFeatureIsBilling(state),
  featureIsMultiBillingInfo: getFeatureIsMultiBillingInfo(state),
  featureIsMultiDays: getFeatureIsMultiDays(state),
  featureIsCreditInvoice: getFeatureIsCreditInvoice(state),
  featureIsSummaryInvoice: getFeatureIsSummaryInvoice(state),
  featureIsSellingExpenses: getFeatureIsSellingExpenses(state),
  featureIsFeeInvoice: getFeatureIsFeeInvoice(state),
  featureIsOrgAttributesFilter: getFeatureIsOrgAttributesFilter(state),
  featureIsPlacementEntities: getFeatureIsPlacementEntities(state),
  featureIsPlannerDistance: getFeatureIsPlannerDistance(state),
  featureIsManagerConfigurable: getFeatureIsManagerConfigurable(state),
  featureIsProjects: getFeatureIsProjects(state),
  featureIsWorkerOnboarding: getFeatureIsWorkerOnboarding(state),
  featureIsExactSolid: getFeatureIsExactSolid(state),
  featureIsSDB: getFeatureIsSDB(state),
  featureIsWorkingTimeRegulations: getFeatureIsWorkingTimeRegulations(state),
  defaultOpenShift: getDefaultOpenShift(state),
  defScheduleType: getPreparedDefaultScheduleType(state),
  isFeatureProtectedWorkersDocuments: getIsFeatureProtectedWorkersDocuments(state),
  isFreemium: getIsFreemium(state),
  isMaintenance: getIsMaintenance(state),
  allowedAgencyFees: getAllowedAgencyFees(state),
  allowedUnavailabilities: getAllowedUnavailabilities(state),
  canCreateOrg: getCanCreateOrg(state),
  isFeatureAgreements: getIsFeatureAgreements(state),
  isSigningDocuments: getIsSigningDocuments(state),
  isExternal: getIsExternal(state),
  isExternalBlocked: getIsExternalBlocked(state),
  isInternalBlocked: getIsInternalBlocked(state),
  isInternalBlockedFletcher: getIsInternalBlockedFletcher(state),
  isInternalBlockedSwzzorg: getIsInternalBlockedSwzzorg(state),
  isInternalBlockedKeistadZorg: getIsBlockedKeistadZorg(state),
  isExternalExcellentFlex: getExternalExcellentFlex(state),
  isYzorgManagers: getIsYzorgManagers(state),
  isInternalBlockedKiz: getIsInternalBlockedKiz(state),
  isLogisticForceManager: getIsLogisticForceManager(state),
  isManagerAccessBilling: getManagerAccessBilling(state),
  isManagerAccessTimeTracking: getManagerAccessTimeTracking(state),
  isManagerAccessSettings: getManagerAccessSettings(state),
  isManagerAccessDeletingActions: getManagerAccessDeletingActions(state),
  isManagerCanPublishShift: getManagerCanPublishShift(state),
  isManagerCanUpdatePendingShift: getManagerCanUpdatePendingShift(state),
  isManagerCanDeletePendingShift: getManagerCanDeletePendingShift(state),
  isManagerCanUpdateActiveShift: getManagerCanUpdateActiveShift(state),
  isManagerCanDeleteActiveShift: getManagerCanDeleteActiveShift(state),
  isManagerCanApproveShift: getManagerCanApproveShift(state),
});

export const FeatureContext = createContext();
export const useFeature = () => useContext(FeatureContext);

export const FeatureProvider = (props) => {
  const {
    allowedAgencyFees,
    allowedUnavailabilities,
    featureIsTimeTracking,
    featureIsAgencyFee,
    featureIsTimeTrackingSign,
    featureIsImportingShifts,
    featureIsBranding,
    featureIsRecurring,
    featureIsExtendedReport,
    featureIsOrtSchema,
    featureIsSimpleOrtSchema,
    featureIsInternalManagers,
    featureIsExpenses,
    featureIsExternalManagers,
    featureIsMessaging,
    featureIsCCemail,
    featureIsCalculated,
    featureIsManualApprove,
    featureIsAdvancedFiltering,
    featureIsCustomFields,
    featureIsMultiRoles,
    featureIsFull,
    featureIsFullDays,
    featureIsCopyShift,
    featureIsWorkerCalendarView,
    featureIsFeeInvoice,
    featureIsFavoriteWorkersList,
    featureIsBlacklistWorkersList,
    featureIsAdditionalWorkerStatuses,
    featureIsStoreFilter,
    featureIsAutoApprove,
    featureIsGigCategorySettings,
    featureIsReview,
    featureIsPublicProfile,
    featureIsNewDashboard,
    featureIsOrgGroup,
    featureIsBilling,
    featureIsMultiBillingInfo,
    featureIsCreditInvoice,
    featureIsMultiDays,
    featureIsOrgAttributesFilter,
    featureIsSummaryInvoice,
    featureIsPlacementEntities,
    featureIsPlannerDistance,
    featureIsManagerConfigurable,
    featureIsSellingExpenses,
    featureIsProjects,
    featureIsWorkerOnboarding,
    featureIsExactSolid,
    featureIsSDB,
    featureIsWorkingTimeRegulations,
    defaultOpenShift,
    defScheduleType,
    isFeatureProtectedWorkersDocuments,
    isFeatureAgreements,
    isSigningDocuments,
    canCreateOrg,
    isExternal,
    isExternalBlocked,
    isInternalBlocked,
    isInternalBlockedFletcher,
    isInternalBlockedSwzzorg,
    isInternalBlockedKeistadZorg,
    isExternalExcellentFlex,
    isYzorgManagers,
    isInternalBlockedKiz,
    isLogisticForceManager,
    isManagerAccessBilling,
    isManagerAccessTimeTracking,
    isManagerAccessSettings,
    isManagerAccessDeletingActions,
    isManagerCanPublishShift,
    isManagerCanUpdatePendingShift,
    isManagerCanDeletePendingShift,
    isManagerCanUpdateActiveShift,
    isManagerCanDeleteActiveShift,
    isManagerCanApproveShift,
  } = props;
  const features = useMemo(() => ({
    feature: {
      allowedAgencyFees,
      allowedUnavailabilities,
      featureIsTimeTracking,
      featureIsAgencyFee,
      featureIsTimeTrackingSign,
      featureIsImportingShifts,
      featureIsBranding,
      featureIsRecurring,
      featureIsExtendedReport,
      featureIsOrtSchema,
      featureIsSimpleOrtSchema,
      featureIsInternalManagers,
      featureIsExpenses,
      featureIsExternalManagers,
      featureIsMessaging,
      featureIsCCemail,
      featureIsCalculated,
      featureIsManualApprove,
      featureIsAdvancedFiltering,
      featureIsCustomFields,
      featureIsMultiRoles,
      featureIsFull,
      featureIsFullDays,
      featureIsCopyShift,
      featureIsWorkerCalendarView,
      featureIsFeeInvoice,
      featureIsFavoriteWorkersList,
      featureIsBlacklistWorkersList,
      featureIsAdditionalWorkerStatuses,
      featureIsStoreFilter,
      featureIsAutoApprove,
      featureIsGigCategorySettings,
      featureIsReview,
      featureIsPublicProfile,
      featureIsNewDashboard,
      featureIsOrgGroup,
      featureIsBilling,
      featureIsMultiBillingInfo,
      featureIsCreditInvoice,
      featureIsMultiDays,
      featureIsOrgAttributesFilter,
      featureIsSummaryInvoice,
      featureIsPlacementEntities,
      featureIsPlannerDistance,
      featureIsManagerConfigurable,
      featureIsSellingExpenses,
      featureIsProjects,
      featureIsWorkerOnboarding,
      featureIsExactSolid,
      featureIsSDB,
      featureIsWorkingTimeRegulations,
      defaultOpenShift,
      defScheduleType,
      isFeatureProtectedWorkersDocuments,
      isFeatureAgreements,
      isSigningDocuments,
      canCreateOrg,
    },
    access: {
      isManagerAccessBilling,
      isManagerAccessTimeTracking,
      isManagerAccessSettings,
      isManagerAccessDeletingActions,
      isManagerCanPublishShift,
      isManagerCanUpdatePendingShift,
      isManagerCanDeletePendingShift,
      isManagerCanUpdateActiveShift,
      isManagerCanDeleteActiveShift,
      isManagerCanApproveShift,
      isExternal,
      isExternalBlocked,
      isInternalBlocked,
      isInternalBlockedFletcher,
      isInternalBlockedSwzzorg,
      isInternalBlockedKeistadZorg,
      isExternalExcellentFlex,
      isYzorgManagers,
      isInternalBlockedKiz,
      isLogisticForceManager,
    }
  }), [
    allowedAgencyFees,
    allowedUnavailabilities,
    featureIsTimeTracking,
    featureIsAgencyFee,
    featureIsTimeTrackingSign,
    featureIsImportingShifts,
    featureIsBranding,
    featureIsRecurring,
    featureIsExtendedReport,
    featureIsOrtSchema,
    featureIsSimpleOrtSchema,
    featureIsInternalManagers,
    featureIsExpenses,
    featureIsExternalManagers,
    featureIsMessaging,
    featureIsCCemail,
    featureIsCalculated,
    featureIsManualApprove,
    featureIsAdvancedFiltering,
    featureIsCustomFields,
    featureIsMultiRoles,
    featureIsFull,
    featureIsFullDays,
    featureIsCopyShift,
    featureIsWorkerCalendarView,
    featureIsFeeInvoice,
    featureIsFavoriteWorkersList,
    featureIsBlacklistWorkersList,
    featureIsAdditionalWorkerStatuses,
    featureIsStoreFilter,
    featureIsAutoApprove,
    featureIsGigCategorySettings,
    featureIsReview,
    featureIsPublicProfile,
    featureIsNewDashboard,
    featureIsOrgGroup,
    featureIsBilling,
    featureIsMultiBillingInfo,
    featureIsCreditInvoice,
    featureIsMultiDays,
    featureIsOrgAttributesFilter,
    featureIsSummaryInvoice,
    featureIsPlacementEntities,
    featureIsPlannerDistance,
    featureIsManagerConfigurable,
    featureIsSellingExpenses,
    featureIsProjects,
    featureIsWorkerOnboarding,
    featureIsExactSolid,
    featureIsSDB,
    featureIsWorkingTimeRegulations,
    defaultOpenShift,
    defScheduleType,
    isFeatureProtectedWorkersDocuments,
    isFeatureAgreements,
    isSigningDocuments,
    canCreateOrg,
    isExternal,
    isExternalBlocked,
    isInternalBlocked,
    isInternalBlockedFletcher,
    isInternalBlockedSwzzorg,
    isInternalBlockedKeistadZorg,
    isExternalExcellentFlex,
    isYzorgManagers,
    isInternalBlockedKiz,
    isLogisticForceManager,
    isManagerAccessBilling,
    isManagerAccessTimeTracking,
    isManagerAccessSettings,
    isManagerAccessDeletingActions,
    isManagerCanPublishShift,
    isManagerCanUpdatePendingShift,
    isManagerCanDeletePendingShift,
    isManagerCanUpdateActiveShift,
    isManagerCanDeleteActiveShift,
    isManagerCanApproveShift,
  ]);

  return (
    <FeatureContext.Provider value={features}>
      {props.children}
    </FeatureContext.Provider>
  );
};

FeatureProvider.propTypes = {
  children: PropTypes.node.isRequired,
  allowedAgencyFees: PropTypes.number.isRequired,
  allowedUnavailabilities: PropTypes.number.isRequired,
  featureIsTimeTracking: PropTypes.bool.isRequired,
  featureIsAgencyFee: PropTypes.bool.isRequired,
  featureIsTimeTrackingSign: PropTypes.bool.isRequired,
  featureIsImportingShifts: PropTypes.bool.isRequired,
  featureIsBranding: PropTypes.bool.isRequired,
  featureIsRecurring: PropTypes.bool.isRequired,
  featureIsExtendedReport: PropTypes.bool.isRequired,
  featureIsOrtSchema: PropTypes.bool.isRequired,
  featureIsSimpleOrtSchema: PropTypes.bool.isRequired,
  featureIsInternalManagers: PropTypes.bool.isRequired,
  featureIsExpenses: PropTypes.bool.isRequired,
  featureIsExternalManagers: PropTypes.bool.isRequired,
  featureIsMessaging: PropTypes.bool.isRequired,
  featureIsCCemail: PropTypes.bool.isRequired,
  featureIsCalculated: PropTypes.bool.isRequired,
  featureIsManualApprove: PropTypes.bool.isRequired,
  featureIsAdvancedFiltering: PropTypes.bool.isRequired,
  featureIsCustomFields: PropTypes.bool.isRequired,
  featureIsMultiRoles: PropTypes.bool.isRequired,
  featureIsFull: PropTypes.bool.isRequired,
  featureIsFullDays: PropTypes.number.isRequired,
  featureIsCopyShift: PropTypes.bool.isRequired,
  featureIsWorkerCalendarView: PropTypes.bool.isRequired,
  featureIsFeeInvoice: PropTypes.bool.isRequired,
  featureIsFavoriteWorkersList: PropTypes.bool.isRequired,
  featureIsBlacklistWorkersList: PropTypes.bool.isRequired,
  featureIsAdditionalWorkerStatuses: PropTypes.bool.isRequired,
  featureIsStoreFilter: PropTypes.bool.isRequired,
  featureIsAutoApprove: PropTypes.bool.isRequired,
  featureIsGigCategorySettings: PropTypes.bool.isRequired,
  featureIsReview: PropTypes.bool.isRequired,
  featureIsPublicProfile: PropTypes.bool.isRequired,
  featureIsNewDashboard: PropTypes.bool.isRequired,
  featureIsOrgGroup: PropTypes.bool.isRequired,
  featureIsBilling: PropTypes.bool.isRequired,
  featureIsMultiBillingInfo: PropTypes.bool.isRequired,
  featureIsCreditInvoice: PropTypes.bool.isRequired,
  featureIsMultiDays: PropTypes.bool.isRequired,
  featureIsOrgAttributesFilter: PropTypes.bool.isRequired,
  featureIsSummaryInvoice: PropTypes.bool.isRequired,
  featureIsPlacementEntities: PropTypes.bool.isRequired,
  featureIsPlannerDistance: PropTypes.bool.isRequired,
  featureIsManagerConfigurable: PropTypes.bool.isRequired,
  featureIsSellingExpenses: PropTypes.bool.isRequired,
  featureIsProjects: PropTypes.bool.isRequired,
  featureIsWorkerOnboarding: PropTypes.bool.isRequired,
  featureIsExactSolid: PropTypes.bool.isRequired,
  featureIsSDB: PropTypes.bool.isRequired,
  featureIsWorkingTimeRegulations: PropTypes.bool.isRequired,
  defaultOpenShift: PropTypes.bool.isRequired,
  defScheduleType: PropTypes.string.isRequired,
  isFeatureProtectedWorkersDocuments: PropTypes.bool.isRequired,
  isFeatureAgreements: PropTypes.bool.isRequired,
  isSigningDocuments: PropTypes.bool.isRequired,
  canCreateOrg: PropTypes.bool.isRequired,
  isExternal: PropTypes.bool.isRequired,
  isExternalBlocked: PropTypes.bool.isRequired,
  isInternalBlocked: PropTypes.bool.isRequired,
  isInternalBlockedFletcher: PropTypes.bool.isRequired,
  isInternalBlockedSwzzorg: PropTypes.bool.isRequired,
  isInternalBlockedKeistadZorg: PropTypes.bool.isRequired,
  isExternalExcellentFlex: PropTypes.bool.isRequired,
  isYzorgManagers: PropTypes.bool.isRequired,
  isInternalBlockedKiz: PropTypes.bool.isRequired,
  isLogisticForceManager: PropTypes.bool.isRequired,
  isManagerAccessBilling: PropTypes.bool.isRequired,
  isManagerAccessTimeTracking: PropTypes.bool.isRequired,
  isManagerAccessSettings: PropTypes.bool.isRequired,
  isManagerAccessDeletingActions: PropTypes.bool.isRequired,
  isManagerCanPublishShift: PropTypes.bool.isRequired,
  isManagerCanUpdatePendingShift: PropTypes.bool.isRequired,
  isManagerCanDeletePendingShift: PropTypes.bool.isRequired,
  isManagerCanUpdateActiveShift: PropTypes.bool.isRequired,
  isManagerCanDeleteActiveShift: PropTypes.bool.isRequired,
  isManagerCanApproveShift: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(FeatureProvider);