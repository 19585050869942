/* eslint-disable*/
import superagent from 'superagent';
import config from '../config';
import cookieHelper from '../utils/cookieHelper';
import globalConst from '../constants';

const methods = ['get', 'post', 'put', 'patch', 'del', 'custom'];
function formatUrl(path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  const isGuestReq = /&guest/.test(path);
  const isV3 = /v3/.test(path);

  if (/fapi/.test(path)) {
    const url = (config.isLive || config.isStage) ? config.fapiHost : `${config.fapiHost}:${config.fapiPort}`;
    const protocol = (config.isLive || config.isStage) ? config.protocolType : 'http';
    return `${protocol}://${url}${adjustedPath}`;
  }

  if (!isGuestReq && !isV3 && cookieHelper.get('type')) {
    const clientType = globalConst.url[cookieHelper.get('type')] || globalConst.CLIENTS_STR;

    return `${config.protocolType}://${config.apiHost}/${config.apiVersion}/${clientType}${adjustedPath}`;
  }

  if (isV3) {
    return `${config.protocolType}://${config.apiHost}/api${adjustedPath}`;
  }

  return `${config.protocolType}://${config.apiHost}/${config.apiVersion}${adjustedPath}`;
}

export default class ApiClient {
  constructor() {
    methods.forEach((method) =>
      this[method] = (path, { params, data, header, responseType } = {}) => new Promise((resolve, reject) => {
        const request = superagent[method](formatUrl(path));
        const locale = cookieHelper.get('locale') || 'en';

        if (params) {
          request.query(params);
        }

        request.set('Access-Control-Allow-Credentials', true);
        request.set('Access-Control-Allow-Headers', 'Content-Type');
        request.set('Access-Control-Allow-Methods', 'OPTIONS,GET,PUT,POST,DELETE');
        request.set('Access-Control-Allow-Origin', '*');
        request.set('Authorization', cookieHelper.get('token'));

        if (header) {
          request.set(header.field, header.value);
        }

        if (responseType) {
          request.responseType(responseType);
        }

        if (data) {
          data.locale = locale;
          if (data.file) {
            request.send(data.file);
          } else {
            request.send(data);
          }
        } else {
          request.send({ locale });
        }

        request.end((err, { body } = {}) => {
          if (err) {
            return reject({ ...body, ...err });
          }

          return resolve(body);
        });
      }));
  }
  empty() {
  }
}
