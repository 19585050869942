import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '../../../components/LoadingComponent';

const PublicRoutes = loadable(
  () => import(
    /* webpackChunkName: "public-page-chunk" */
    './PublicRoutes'
  ),
  {
    fallback: (<LoadingComponent />),
  }
);

export default PublicRoutes;