import React from 'react';
import { css } from '@emotion/react';
import cookieHelper from '../../utils/cookieHelper';

const TimeTrackerPageLoading = () => {
  const titleCss = css({
    height: '34px',
    background: '#dcdcdc',
    borderRadius: '3px',
  });
  const btnDefCss = css({
    height: '30px',
    width: '75px',
    backgroundColor: '#fff',
    borderRadius: '3px',
    boxShadow: '0 1px 5px 0 rgb(0 0 0 / 20%)',
  });

  const btnNormalWith = css([btnDefCss, { width: '150px' }]);
  const btnSmallWith = css([btnDefCss, { width: '50px' }]);
  const btnSmall = css([btnDefCss, { height: '26px' }]);
  const btnSmallHeight = css([btnSmallWith, { height: '26px' }]);

  const renderHeader = () => (
    <div className="pageMainWrapper__header">
      <div className="pageMainWrapper__header-left --marginRight-25">
        <div
          className="backgroundLoading"
          css={titleCss}
        />
      </div>
      <div className="pageMainWrapper__header-controls --marginLeft-25">
        <div css={btnNormalWith} />
        <div className="--marginLeft-10" css={btnNormalWith} />
      </div>
    </div>
  );

  const labelCss = css({
    height: '16px',
    width: '70%',
    backgroundColor: '#9a9a9a',
    borderRadius: '3px',
  });
  const inputCss = css({
    height: '28px',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #dcdcdc',
    borderRadius: '3px',
    marginTop: '8px',
    marginBottom: '20px',
  });

  const renderSelector = () => (
    <div>
      <div className="backgroundLoading" css={labelCss} />
      <div css={inputCss} />
    </div>
  );

  const wrapFieldCss = css({
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
  });
  const boxCss = css({
    height: '22px',
    width: '22px',
    backgroundColor: '#eaeaea',
    borderRadius: '3px',
  });

  const renderCheckbox = (index = '') => (
    <div key={index} css={wrapFieldCss}>
      <div css={boxCss} />
      <div className="--marginLeft-10" css={labelCss} />
    </div>
  );

  const renderCheckboxBlock = () => (
    <div className="--marginTop-20">
      <div className="backgroundLoading" css={labelCss} />
      {renderCheckbox()}
    </div>
  );

  const renderCheckList = () => (
    <div className="--marginTop-20">
      <div className="backgroundLoading" css={labelCss} />
      {[...Array(7).keys()].map((index) => renderCheckbox(index))}
    </div>
  );

  const renderFilterBar = () => (
    <div
      className="filterBar"
      css={{
        width: cookieHelper.get('filterBarWidth') ? +cookieHelper.get('filterBarWidth') : 'auto',
      }}
    >
      <div className="filterBar__wrap">
        <div className="filterBar__actions">
          <div css={btnSmall} />
          <div
            className="--marginLeft-10"
            css={btnSmallHeight}
          />
        </div>
        <div className="filterBar__container">
          <div className="filtersList --marginTop-10">
            {renderSelector()}
            {renderSelector()}
            {renderSelector()}

            {renderCheckboxBlock()}

            {renderCheckList()}
          </div>
        </div>
      </div>
    </div>
  );

  const tableHeaderDay = css({
    height: '12px',
    width: '22px',
    backgroundColor: '#9a9a9a',
    borderRadius: '3px',
  });
  const tableHeaderDate = css({
    marginTop: '5px',
    height: '35px',
    width: '45px',
    backgroundColor: '#eaeaea',
    borderRadius: '3px',
  });

  return (
    <div className="pageMainWrapper">
      {renderHeader()}
      <div className="pageMainWrapper__container">
        <div className="pageMainWrapper__container--collapse">
          {renderFilterBar()}
          <div className="timeTracking__content">
            <div className="timeTracking__content-table">
              <table className="table timetraking">
                <thead className="table__header">
                  <tr className="table__header__row --dateRow">
                    <th className="table__header__cell --fixTop --fixLeft" aria-label="empty1" />
                    {[...Array(7).keys()].map((index) => (
                      <th
                        key={index}
                        className="table__header__cell --dateHeaderCell --fixTop"
                        aria-label="date"
                      >
                        <div css={tableHeaderDay} />
                        <div className="--dateHeaderDay">
                          <div css={tableHeaderDate} />
                        </div>
                      </th>
                    ))}
                  </tr>
                  <tr className="table__header__row --titleRow">
                    <th className="table__header__cell --fixSecondTop --fixLeft" aria-label="empty1" />
                    <th className="table__header__cell --fixSecondTop  --fixSecondLeft" aria-label="empty2" />
                    <th className="table__header__cell --fixSecondTop" aria-label="empty3" />
                    <th className="table__header__cell --fixSecondTop" aria-label="empty4" />
                    <th className="table__header__cell --fixSecondTop" aria-label="empty5" />
                    <th className="table__header__cell --fixSecondTop" aria-label="empty6" />
                    <th className="table__header__cell --fixSecondTop" aria-label="empty7" />
                    <th className="table__header__cell --fixSecondTop" aria-label="empty8" />
                  </tr>
                </thead>
                <tbody className="table__body">
                  <tr className="table__body__row" />
                </tbody>
              </table>
            </div>
            <footer className="timeTracking__content-footer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTrackerPageLoading;
