import { toast } from 'react-toastify';
import globalConst from '../constants';

const OPTIONS = {
  position: 'bottom-center',
  theme: 'colored',
  hideProgressBar: true,
  autoClose: 4000,
};

export const showError = (err) => {
  toast.error(err.message, OPTIONS);
};

export const showWarning = (message) => {
  toast.warn(message, OPTIONS);
};

export const showMessage = (message) => {
  toast.success(message, OPTIONS);
};

/**
 * Handle key up event for input field
 * @param {object} event SyntheticEvent
 */
export const inputKeyUpEvent = (event) => {
  if (event.keyCode === globalConst.ENTER_KEY) {
    event.target.blur();
  }
};
