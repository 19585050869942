import { css, keyframes } from '@emotion/react';

export const defaultCircleBtn = {
  borderRadius: '50%',
  padding: 0,
  border: 0,
  lineHeight: 0,
  outline: 0,
  cursor: 'pointer',
  background: '#fff',
  boxShadow: '0 1px 2px rgba(0,0,0,.2)',
  '&:active': {
    transform: 'scale(.95)',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    color: '#E9E9EC',
  }
};

export const selectStyles = (options) => {
  const defaultOptions = {
    error: '',
    minHeight: '34px',
    maxHeight: '36px',
    marginTop: '5px',
    ...options,
  };

  const placeholderError = !defaultOptions.error ? '#B2B4BD' : '#be3c60';

  return ({
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),
    valueContainer: (styles, state) => ({
      ...styles,
      padding: '1px 8px',
      maxHeight: defaultOptions.maxHeight,
      overflow: state.isMulti ? 'auto' : 'unset',
    }),
    singleValue: (styles, state) => ({
      ...styles,
      color: !state.isDisabled ? '#262626' : '#595A5B',
    }),
    control: (styles, state) => {
      let border = !state.isDisabled ? '1px solid #DFE0E4' : '1px solid #E9E9EC';
      let backgroundColor = !state.isDisabled ? '#FFFFFF' : '#E9E9EC';
      let boxShadow = defaultOptions.boxShadow ? defaultOptions.boxShadow : '0 0 0 2px #B2B4BD';

      if (defaultOptions.error) {
        border = '1px solid #ffeff1';
        backgroundColor = '#ffeff1';
        boxShadow = '0 0 0 2px #be3c60';
      }

      return ({
        ...styles,
        minHeight: defaultOptions.minHeight,
        borderRadius: '3px',
        border,
        span: {
          display: 'none'
        },
        backgroundColor,
        boxShadow: 'unset',
        '&:hover': {
          borderColor: defaultOptions.borderColor ? defaultOptions.borderColor : '#B2B4BD',
        },
        '&:focus-within': {
          borderColor: '#fff',
          boxShadow,
        },
      });
    },
    dropdownIndicator: (styles, state) => ({
      ...styles,
      padding: '2px 5px',
      color: !state.isDisabled ? '#262626' : '#E9E9EC',
    }),
    clearIndicator: (styles) => ({ ...styles, padding: '2px 5px' }),
    placeholder: (styles) => ({
      ...styles,
      color: defaultOptions.placeholderColor ? defaultOptions.placeholderColor : placeholderError,
    }),
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
    menu: (styles) => ({
      ...styles,
      zIndex: 2,
      marginTop: defaultOptions.marginTop,
    }),
    option: (styles, state) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      backgroundColor: (state.isSelected || state.isFocused) ? '#DFE0E4' : 'transparent',
      color: '#262626',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:active': {
        backgroundColor: '#B2B4BD',
      }
    }),
  });
};

export const baseInputStyles = {
  backgroundColor: '#fff',
  border: '1px solid #DFE0E4',
  outline: 0,
  borderRadius: '3px',
  padding: '8px 10px',
  width: '100%',
  minWidth: '100px',
  color: '#262626',
  transition: 'all 100ms',
  '&::placeholder': {
    color: '#B2B4BD',
  },
  '&:hover': {
    borderColor: '#B2B4BD'
  },
  '&:focus': {
    borderColor: '#fff',
    boxShadow: '0 0 0 2px #B2B4BD',
  },
};

export const cleanInputStyles = {
  margin: 0,
  outline: 0,
  border: 0,
  padding: 0,
  background: 'transparent',
};

const changeColor = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const bgLoadingCss = css({
  background: 'linear-gradient(-45deg, #dcdcdc, #dfdfdf, #9e9e9e, #dcdcdc)',
  backgroundSize: '400% 400%',
  animation: `${changeColor} 5s ease infinite`,
});

export const debugBase64 = (base64URL) => {
  const win = window.open();
  win.document.write(`<iframe src="${base64URL}" 
  frameborder="0" style="border:0; 
  top:0px; left:0px; bottom:0px; 
  right:0px; width:100%; 
  height:100%;" 
  allowfullscreen></iframe>`);
};
