import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import {
  getProfile,
  getUserType,
} from '../../services/selectors';
import { useTranslation } from '../../contexts/TranslationContext';
import { ProfileContext } from '../../contexts/ProfileContext';
import FeatureProvider from '../../contexts/FeatureContext';
import App from '../App';
import AgreementsPage from '../AgreementsPage';
import NotFoundPage from '../NotFoundPage';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import ForgotPWDConfirmPage from '../ForgotPWDConfirmPage';
import ForgotWorkerPWDConfirmPage from '../ForgotWorkerPWDConfirmPage';
import ConfirmationPage from '../ConfirmationPage';
import ErrorBoundary from '../../components/ErrorBoundary';
import LoginPage from '../LoginPage';
import SignupPage from '../SignupPage';
import ForgotPWDPage from '../ForgotPWDPage';
import ManagerInvitation from '../ManagersPage/ManagerInvitation';
import DashboardPage from '../DashboardPage';
import ShiftCreatePage from '../ShiftCreatePage';
import ShiftDetailPage from '../ShiftDetailPage';
import GigImportPage from '../GigImportPage';
import GigMultiplePage from '../GigMultiplePage';
import ManagerPage from '../ManagerPage';
import ManagersPage from '../ManagersPage';
import MyProfile from '../MyProfile';
import OrganizationsPage from '../OrganizationsPage';
import OrganizationsEnrichPage from '../OrganizationsEnrichPage';
import OrganizationsImportPage from '../OrganizationsImportPage';
import OrganizationPage from '../OrganizationPage';
import PlanningPage from '../PlanningPage';
import ReviewPage from '../ReviewPage';
import ReportsPage from '../ReportsPage';
import SettingsMainPage from '../SettingsMainPage';
import ProjectsPage from '../ProjectsPage';
import ProjectCreatePage from '../ProjectPage/ProjectCreatePage';
import ProjectDetailPage from '../ProjectPage/ProjectDetailPage';
import TimeTrackerPage from '../TimeTrackerPage';
import WorkerPage from '../WorkerPage';
import WorkerCalendar from '../WorkerCalendar';
import WorkersPage from '../WorkersPage';
import WorkersInvitePage from '../WorkersInvitePage';
import WorkersImportPage from '../WorkersImportPage';
import WorkersEnrichPage from '../WorkersEnrichPage';
import BillingOverviewPage from '../BillingOverviewPage';

const mapStateToProps = (state) => ({
  profile: getProfile(state),
  userType: getUserType(state),
});

const RouterContainer = (props) => {
  const {
    userType,
    profile,
  } = props;
  const { currentLang } = useTranslation();

  const profileObj = useMemo(() => ({
    profile,
    userType,
  }), [profile, userType]);

  const protectedRouters = [
    { path: 'billing', element: <BillingOverviewPage /> },
    { path: 'dashboard', element: <DashboardPage /> },
    { path: 'gig/import', element: <GigImportPage /> },
    { path: 'gigs', element: <GigMultiplePage /> },
    { path: 'managers', element: <ManagersPage /> },
    { path: 'manager/:id', element: <ManagerPage /> },
    { path: 'myprofile', element: <MyProfile /> },
    { path: 'organizations/enrich', element: <OrganizationsEnrichPage /> },
    { path: 'organizations/import', element: <OrganizationsImportPage /> },
    { path: 'organizations', element: <OrganizationsPage /> },
    { path: 'organization/:id', element: <OrganizationPage /> },
    { path: 'planning-shifts', element: <PlanningPage /> },
    { path: 'projects', element: <ProjectsPage /> },
    { path: 'project/:id', element: <ProjectDetailPage /> },
    { path: 'project', element: <ProjectCreatePage /> },
    { path: 'reporting', element: <ReportsPage /> },
    { path: 'review', element: <ReviewPage /> },
    { path: 'settings-main/:type', element: <SettingsMainPage /> },
    { path: 'settings-main/:type/:id', element: <SettingsMainPage /> },
    { path: 'shift/:id', element: <ShiftDetailPage /> },
    { path: 'shift', element: <ShiftCreatePage /> },
    { path: 'time-tracking', element: <TimeTrackerPage /> },
    { path: 'workers/enrich', element: <WorkersEnrichPage /> },
    { path: 'workers/invite', element: <WorkersInvitePage /> },
    { path: 'workers/import', element: <WorkersImportPage /> },
    { path: 'agreements', element: <AgreementsPage /> },
    { path: 'workers', element: <WorkersPage /> },
    { path: 'worker/:id', element: <WorkerPage /> },
    { path: 'worker/:id/calendar', element: <WorkerCalendar /> },
  ];
  const commonRouters = [
    { path: 'clients/confirmation', element: (<ErrorBoundary><ConfirmationPage /></ErrorBoundary>) },
    { path: 'clients/password/edit', element: (<ErrorBoundary><ForgotPWDConfirmPage /></ErrorBoundary>) },
    { path: 'managers/password/edit', element: (<ErrorBoundary><ForgotPWDConfirmPage /></ErrorBoundary>) },
    { path: 'workers/password/edit', element: (<ErrorBoundary><ForgotWorkerPWDConfirmPage /></ErrorBoundary>) },
  ];
  const publicRouters = [
    { path: 'login', element: <LoginPage /> },
    { path: 'signup', element: <SignupPage /> },
    { path: 'forgot', element: <ForgotPWDPage /> },
    { path: 'managers/invitation/accept', element: <ManagerInvitation /> },
  ];

  const redirectPath = profile.email ? 'dashboard' : 'login';

  return (
    <ProfileContext.Provider value={profileObj}>
      <FeatureProvider>
        <App currentLang={currentLang}>
          <Routes>
            {/* Common routers with/without auth */}
            {commonRouters.map((commonRoute) => (
              <Route key={commonRoute.path} path={commonRoute.path} element={commonRoute.element} />
            ))}

            {/* Public routers without auth */}
            <Route
              element={(
                <PublicRoutes
                  currentLang={currentLang}
                  profile={profile}
                  userType={userType}
                />
              )}
            >
              {publicRouters.map((publicRouter) => (
                <Route key={publicRouter.path} path={publicRouter.path} element={publicRouter.element} />
              ))}
            </Route>

            {/* Protected routers, with auth */}
            <Route
              element={(
                <ProtectedRoutes
                  currentLang={currentLang}
                  isAuthenticated={profile.email}
                />
              )}
            >
              {protectedRouters.map((protectedRoute) => (
                <Route key={protectedRoute.path} path={protectedRoute.path} element={protectedRoute.element} />
              ))}
            </Route>

            <Route path="/" element={(<Navigate to={`/${currentLang}/${redirectPath}`} replace />)} />
            <Route path="*" element={(<NotFoundPage currentLang={currentLang} />)} />
          </Routes>
        </App>
      </FeatureProvider>
    </ProfileContext.Provider>
  );
};

RouterContainer.displayName = 'RouterContainer';
RouterContainer.propTypes = {
  profile: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(RouterContainer);
