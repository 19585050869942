import React from 'react';
import loadable from '@loadable/component';
import TimeTrackerPageLoading from './TimeTrackerPageLoading';

const TimeTrackerPage = loadable(
  () => import(
    /* webpackChunkName: "worklogs-page-chunk" */
    './TimeTrackerPage'
  ),
  {
    fallback: (
      <TimeTrackerPageLoading />
    ),
  }
);

export default TimeTrackerPage;
